/* Kipper logo styling */

.kipper-logo-container a {
	margin: 0 !important;
}

.kipper-logo {
	height: 4rem;
	margin: 0 0.5rem 0 0;
}

.kipper-logo-identifier h1 {
	font-size: 28px;
	line-height: 34px;
}
